<template>
  <div class="app-container">
    <div class="total-layout">
      <el-row :gutter="11">
        <el-col :span="12" v-show="adminInformation.type">
          <div class="total-frame" @click="toFirm" >
            <img :src="img_firm" class="total-icon" />
            <div class="total-title">
              <el-link :underline="false">律所总数 </el-link>
            </div>

<!--            <div class="total-title">律所总数</div>-->
            <div class="total-value">{{firmTotal}}</div>
          </div>
        </el-col>
        <el-col :span="12" v-show="adminInformation.type">
          <div class="total-frame" @click="toLawyer">

            <img :src="img_lawyer"  class="total-icon" />
            <div class="total-title">
              <el-link :underline="false">律师总数 </el-link>
            </div>
<!--            <div class="total-title" >律师总数</div>-->
            <div class="total-value">{{lawyerTotal}}</div>

          </div>
        </el-col>
        <el-col :span="12" v-show="!adminInformation.type">
          <div class="total-frame" @click="toLawyer_F">
            <img :src="img_lawyer" class="total-icon" />
            <div class="total-title">
              <el-link :underline="false">律师总数</el-link>
            </div>
<!--            <div class="total-title">律师总数</div>-->
            <div class="total-value">{{lawyerTotal}}</div>
            <!--            <div class="total-value">{{lawyerTotal_F}}</div>-->
          </div>
        </el-col>
<!--        <el-col :span="8">-->
<!--          <div class="total-frame" @click="toAdmin">-->
<!--            <img :src="img_train" class="total-icon" />-->
<!--            <div class="total-title">管理员</div>-->
<!--            <div class="total-value">{{adminTotal}}</div>-->
<!--          </div>-->
<!--        </el-col>-->
      </el-row>
    </div>

    <div class="un-handle-layout">
      <div class="layout-title" >待处理事务</div>
      <div class="un-handle-content">
        <el-row :gutter="20">
          <el-col :span="8"  v-show="adminInformation.type">
            <div class="un-handle-item" @click="toUnauditTrain">
              <el-link  :underline="false">待审核培训： </el-link>
<!--              <span class="font-medium">待审核培训： </span>-->
              <span class="color-danger">{{unauditTrain}}</span>
            </div>
          </el-col>
          <el-col :span="8" v-show="adminInformation.type">
            <div class="un-handle-item" @click="toUnauditApplication">
              <el-link  :underline="false">待审核学时申报： </el-link>
<!--              <span class="font-medium" >待审核学时申报：</span>-->
              <span  class="color-danger">{{unauditApplication}}</span>
            </div>
          </el-col>
          <el-col :span="8" v-show="adminInformation.type">
            <div class="un-handle-item" @click="toUnauditBatch">
              <el-link  :underline="false">待审核批量申报： </el-link>
<!--              <span class="font-medium">待审核批量申报：</span>-->
              <span class="color-danger" >{{unauditBatch}}</span>
            </div>
          </el-col>

          <el-col :span="8" v-show="!adminInformation.type">
            <div class="un-handle-item" @click="toUnauditApplication_F">
              <el-link  :underline="false">待审核学时申报： </el-link>
<!--              <span class="font-medium" >待审核学时申报：</span>-->
              <span  class="color-danger">{{unauditApplication}}</span>
            </div>
          </el-col>

        </el-row>
      </div>
    </div>
    <div class="statistics-layout">
      <div class="layout-title">近期培训</div>
      <!--表格-->
      <el-table
        stripe
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#333' }"
      >
        <el-table-column prop="startTime" label="开始时间" align="center"></el-table-column>
        <el-table-column prop="title" label="培训主题" align="center"></el-table-column>
        <el-table-column prop="type" label="培训类型" align="center" ></el-table-column>
        <el-table-column prop="dname" label="主办方" align="center" width="250"></el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center"  width="150"></el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="5"
        :total="total"
        max-height="400px"
        style="margin-top: 20px; text-align: right">
      </el-pagination>


    </div>
  </div>
</template>

<script>
import { register_type_dict,train_status_dict, train_consumer_dict,train_type_dict,train_status_options, train_consumer_options } from '@/utils/dict'

import { request } from '@/network/network'
import img_train from "@/assets/train.png"
import img_lawyer from "@/assets/lawyer.png"
import img_firm from "@/assets/firm.png"
import router from '@/router'

export default {
  name: "home",
  data() {
    return {
      click: true,
      click_F: true,
      adminInformation: JSON.parse(sessionStorage.getItem("information")) ,
      trainTotal:0,
      lawyerTotal:0,
      firmTotal:0,
      unauditTrain:0,
      unauditApplication:0,
      unauditBatch:0,
      adminTotal:0,

      total:0,
      tableData:[],
      currentPage:1,

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date()
              let start = new Date()
              start.setFullYear(2018)
              start.setMonth(10)
              start.setDate(1)
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "最近一月",
            onClick(picker) {
              const end = new Date()
              let start = new Date()
              start.setFullYear(2018)
              start.setMonth(10)
              start.setDate(1)
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            }
          }
        ]
      },
      orderCountDate: "",
      chartSettings: {
        xAxisType: "time",
        area: true,
        axisSite: { right: ["orderAmount"] },
        labelMap: { orderCount: "订单数量", orderAmount: "订单金额" }
      },
      chartData: {
        columns: [],
        rows: []
      },
      loading: false,
      dataEmpty: false,
      img_firm,
      img_lawyer,
      img_train
    }
  },

  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRecentTrains(val);
    },

    toFirm(){if(this.click){this.click=false, this.$router.push('userManageA/firmManageA')}},
    toLawyer(){if(this.click){this.click=false,this.$router.push('userManageA/lawyerManageA')}},
    toAdmin(){if(this.click){this.click=false,this.$router.push('systemA/adminManageA')}},
    toUnauditTrain(){if(this.click){this.click=false,this.$router.push('trainManageA/trainAuditA')}},
    toUnauditApplication(){if(this.click){this.click=false,this.$router.push('lawyerApplyManageA/lawyerApplyAuditA')}},
    toUnauditBatch(){if(this.click){this.click=false,this.$router.push('lawyerApplyManageA/batchApplyAuditA')}},

    toLawyer_F(){if(this.click_F){this.click_F=false, this.$router.push('userManageF/lawyerManageF')}},
    toUnauditApplication_F(){if(this.click_F){this.click_F=false, this.$router.push('lawyerApplyManageF/lawyerApplyAuditF')}},


    getTrains() {
      return request({
        url: '/train/trains',
        method: 'get',
        params: {
          status: 2,
        }
      }).then(res => {
        this.Traintotal = res.data.total
      })
    },
    getAdmins(){
      return request({
        url: '/admin/admins',
        method:'get',
        params:{
          type: 1
        }
      }).then(res => {
        this.adminTotal = res.data.total
      })
    },
    getFirms() {
      return request({
        url: 'department/departments',
        method: 'get',
        params: {}
      }).then(res => {
        this.firmTotal = res.data.total
      })
    },
    getLawyers() {
      return request({
        url: '/user/users',
        method: 'get',
        params: {}
      }).then(res => {
        this.lawyerTotal = res.data.total
      })
    },
    getUnauditTrains() {
      return request({
        url: '/train/trains',
        method: 'get',
        params: {
          status: 0,
          dtype: 0
        }
      }).then(res => {
        this.unauditTrain = res.data.total
      })
    },
    getApplications() {
      return request({
        url: '/application/applications',
        method: 'get',
        params: {
          status: 2,
        }
      }).then(res => {
        this.unauditApplication = res.data.total
      })
    },
    getBatchs() {
      return request({
        url: '/batch/batchs',
        method: 'get',
        params: {
          status: 2,
        }
      }).then(res => {
        this.unauditBatch = res.data.total
      })
    },
    getRecentTrains(page) {
      return request({
        url: '/train/recentTrains',
        method: 'get',
        params: {
          page: page,
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list
        this.tableData = temp.map(item => {
          item.status = train_status_dict[item.status];
          item.consumer =  train_consumer_dict[item.consumer];
          item.type = train_type_dict[item.type]
          return item
        })
      })
    },
    getData_A(){
      this.getAdmins()
      this.getFirms()
      this.getLawyers()
      this.getUnauditTrains()
      this.getApplications()
      this.getBatchs()
      this.getRecentTrains()
    },
    //律所端
    getLawyers_F() {
      return request({
        url: '/user/users',
        method: 'get',
        params: {
          did:this.adminInformation.did
        }
      }).then(res => {
        this.lawyerTotal = res.data.total
      })
    },

    getApplications_F() {
      return request({
        url: '/application/applications',
        method: 'get',
        params: {
          did: this.adminInformation.did,
          status: 0,
        }
      }).then(res => {
        this.unauditApplication = res.data.total
      })
    },
    getData_F(){
      this.getRecentTrains()
      this.getLawyers_F()
      this.getApplications_F()
    },
  },
  created() {
    if(this.adminInformation.type){
      this.getData_A();
    }
    else{
      this.getData_F();
    }
  }
};
</script>

<style scoped>
.font-medium {
  font-size: 16px;
  color: #909399;

}
.total-frame {
  border: 1px solid #dcdfe6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409eff;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 20px;
  color: #19a6e7;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 10px;
  border: 1px solid #dcdfe6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #f2f6fc;
  font-weight: bold;
}

.un-handle-content {
  padding: 10px 40px;
  font-size: 16px;
  color: #909399;

}

.un-handle-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px;
}

.color-danger {
  color: #19a6e7;
  font-size: 18px;

}


.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #dcdfe6;
}

.statistics-layout {
  margin-top: 20px;
  border: 1px solid #dcdfe6;
}

</style>
